import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CountTo from 'components/CountTo';
import VisibilitySensor from 'react-visibility-sensor';
import withResponsive from 'containers/Responsive/withResponsive';
import { Wrapper, InnerWrapper, Counters, CountWrapper } from './styled';

function Count({ counters, isMobile }) {
  const [visible, setVisible] = useState(false);

  const handleVisibilityChange = isVisible => {
    if (!visible) {
      setVisible(isVisible);
    }
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <VisibilitySensor partialVisibility={isMobile} onChange={handleVisibilityChange} active={!visible}>
          <Counters>
            {counters.map(counter => (
              <CountWrapper key={counter.label}>
                <CountTo ready={visible} {...counter} />
              </CountWrapper>
            ))}
          </Counters>
        </VisibilitySensor>
      </InnerWrapper>
    </Wrapper>
  );
}

Count.propTypes = {
  counters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isMobile: PropTypes.bool.isRequired
};

export default withResponsive(Count);
