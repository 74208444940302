/* eslint-disable func-names */

import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Outer, Inner } from 'containers/Wrapper';
import CloudinaryImage from 'components/Image';
import { map } from 'config/protection';
import { magenta } from 'styles/variables';
import Tippy from '@tippy.js/react';
import {
  Wrapper,
  Image,
  SVG,
  ImageWrapper,
  Tooltip,
  GlobalTippyStyles
} from './styled';

function HotMap() {
  const [size, setSize] = useState({
    width: 2,
    height: 2,
    naturalWidth: 1,
    naturalHeight: 1
  });

  const imageRef = useRef(null);

  const getImageSize = () => {
    setSize({
      width: imageRef.current.clientWidth,
      height: imageRef.current.clientHeight,
      naturalWidth: imageRef.current.naturalWidth,
      naturalHeight: imageRef.current.naturalHeight
    });
  };

  useEffect(() => {
    window.addEventListener('resize', getImageSize);
    return function() {
      window.removeEventListener('resize', getImageSize);
    };
  });

  return (
    <Wrapper>
      <GlobalTippyStyles />
      <Outer>
        <Inner>
          <ImageWrapper>
            <Image
              src={CloudinaryImage.getImageUrlById('protection/worldmap-newer')}
              ref={imageRef}
              onLoad={getImageSize}
            />
            <SVG
              viewBox={`0 0 ${size.width} ${size.height}`}
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform={`scale(${size.width /
                  size.naturalWidth}, ${size.height / size.naturalHeight})`}
              >
                {map.countries.map(country => (
                  <Tippy
                    key={country.name}
                    content={
                      <Tooltip>
                        <h4>
                          <FormattedMessage
                            id={`protection_viz${country.name}Title`}
                          />
                        </h4>
                        <p>
                          <FormattedMessage
                            id={`protection_viz${country.name}Body`}
                          />
                        </p>
                      </Tooltip>
                    }
                    arrow
                    animation="fade"
                    duration={[250, 0]}
                    trigger="click"
                    zIndex={4}
                  >
                    <circle
                      key={country.name}
                      id={country.name}
                      cx={`${country.cx}`}
                      cy={`${country.cy}`}
                      r="16"
                      stroke={`${magenta}`}
                      fill={`${magenta}`}
                    />
                  </Tippy>
                ))}
              </g>
            </SVG>
          </ImageWrapper>
        </Inner>
      </Outer>
    </Wrapper>
  );
}

HotMap.propTypes = {};

export default HotMap;
