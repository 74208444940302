import React from 'react';
import CountTo from 'modules/CountTo';
import CalloutStamp from 'modules/CalloutStamp';
import HotMap from 'modules/HotMap';
import { counters } from 'config/protection';
import Responsive from 'containers/Responsive';
import { Wrapper } from './styled';

const Block = () => (
  <Wrapper>
    <CalloutStamp
      stamp="fingerprint"
      calloutId="protection_calloutPasttenyears"
    />
    <CountTo counters={counters} />
    <Responsive only="desktop">
      <HotMap />
    </Responsive>
  </Wrapper>
);

Block.propTypes = {};

export default Block;
