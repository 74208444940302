import React from 'react';
import { theme } from 'config/protection.json';
import { withIntl } from 'i18n';
import { FormattedMessage } from 'react-intl';
import Template from 'modules/ChapterTemplate';
import CalloutStampBlock from 'modules/CalloutStamp';
import InfoBoxBlock from 'modules/InfoBox';
import Videos from 'modules/Videos';
import Callout from 'components/Callout';
import Bock2Parallax from 'blocks/protection/Block-2-Parallax';
import Block8Map from 'blocks/protection/Block-8-Map';
import InfoCol from 'modules/InfoCollage';
import InfoParallax from 'modules/InfoParallax';
import Conclusion from 'modules/Conclusion';
import Noise from 'components/Sound/Noise';
import styled from 'styled-components';

const InfoCollage = styled(InfoCol)`
  ul {
    columns: 2;
  }
`;

const Protection = () => (
  <Template theme={theme}>
    <CalloutStampBlock
      stamp="fingerprint"
      calloutId="protection_calloutWhathappensTitle"
      subtextId="protection_calloutWhathappensBody"
    />
    <Bock2Parallax />
    <InfoBoxBlock
      id="protection_infoFacesofspBody"
      col={{
        xs: 12,
        sm: 10,
        smOffset: 1,
        md: 6,
        mdOffset: 3
      }}
    />
    <Videos
      videos={[
        'protection/videoDao',
        'protection/videoMyint',
        'protection/videoVidal',
        'protection/videoApolonia'
      ]}
    />
    <Callout size="tears">
      <h3>
        <FormattedMessage id="protection_calloutEveryoneright" />
      </h3>
      <p>
        <FormattedMessage id="protection_quoteEveryonerightBody" />
      </p>
      <p className="caption">
        <FormattedMessage id="protection_quoteEveryonerightCaption" />
      </p>
    </Callout>
    <Noise id="protection/hospital" volume={90}>
      <InfoCollage
        collage={{
          colProps: {
            xs: 12,
            mdOffset: 3,
            md: 10,
            lgOffset: 3,
            lg: 10
          },
          layers: [
            'protection/iraq-hospital-1',
            'protection/iraq-hospital-2',
            'protection/iraq-hospital-3'
          ],
          caption: {
            message: 'protection_imageNurseCaption',
            colProps: {
              xs: 12,
              sm: 5,
              smOffset: 6,
              mdOffset: 5,
              lgOffset: 6
            }
          }
        }}
        infoBoxes={[
          {
            colProps: {
              xs: 12,
              sm: 10,
              smOffset: 1,
              md: 6,
              mdOffset: 0,
              lg: 7,
              lgOffset: 0
            },
            message: 'protection_infoYourspBody',
            justify: 'flex-end',
            position: {
              sm: 'relative'
            }
          }
        ]}
        voffset={{
          sm: '0 0 0rem',
          lg: '0 0 20rem',
          md: '0 0 10rem'
        }}
      />
    </Noise>
    <Videos videos="protection/videoMaya" />
    <InfoParallax
      figure={{
        colProps: {
          xs: 12,
          sm: 10,
          smOffset: 3,
          md: 8,
          mdOffset: 0
        },
        image: 'protection/usa-poverty',
        caption: 'protection_imageHomelessCaption'
      }}
      infoBox={{
        colProps: {
          xs: 12,
          sm: 6,
          md: 5,
          mdOffset: 6
        },
        message: 'protection_infoEndpovertyBody',
        justify: {
          sm: 'center',
          md: 'center',
          lg: 'center'
        }
      }}
      voffset={{
        lg: '0',
        md: '0',
        sm: '0',
        xs: '0'
      }}
    />
    <Noise id="protection/market" volume={90}>
      <InfoCollage
        collage={{
          colProps: {
            xs: 12,
            mdOffset: 2,
            md: 11,
            lgOffset: 2,
            lg: 11
          },
          layers: [
            'protection/ecuador-flowersale-1',
            'protection/ecuador-flowersale-2',
            'protection/ecuador-flowersale-3'
          ],
          caption: {
            message: 'protection_imageVendorCaption',
            colProps: {
              xs: 12,
              sm: 5,
              smOffset: 4,
              mdOffset: 4,
              lgOffset: 4
            }
          }
        }}
        infoBoxes={[
          {
            colProps: {
              xs: 12,
              sm: 8,
              smOffset: 2,
              md: 5,
              mdOffset: 0,
              lg: 7,
              lgOffset: 0
            },
            message: 'protection_infoTimeforactionBody',
            justify: 'flex-start',
            position: {
              sm: 'relative'
            }
          }
        ]}
        voffset={{
          lg: '10rem 0 0',
          md: '6rem 0 0',
          sm: '0'
        }}
      />
    </Noise>
    <Block8Map />
    <Noise id="protection/maternity-ward" volume={70}>
      <InfoCollage
        collage={{
          colProps: {
            xs: 12,
            md: 11,
            mdOffset: -1
          },
          layers: [
            'protection/sierra-babies-1',
            'protection/sierra-babies-2',
            'protection/sierra-babies-3'
          ],
          caption: {
            message: 'protection_imageNursesnewCaption',
            colProps: {
              xs: 12,
              sm: 5,
              smOffset: 4,
              mdOffset: 4,
              lgOffset: 4
            }
          }
        }}
        infoBoxes={[
          {
            colProps: {
              xs: 12,
              sm: 8,
              smOffset: 2,
              mdOffset: 6,
              md: 5,
              lg: 6
            },
            message: 'protection_infoRoadaheadBody',
            justify: 'flex-start',
            position: {
              sm: 'relative'
            }
          }
        ]}
        voffset={{
          lg: '10rem 0 0',
          md: '8rem 0 0',
          sm: '0'
        }}
      />
    </Noise>
    <Conclusion
      mainTextId="protection_calloutUniversalsp"
      subTextId="protection_calloutOnlywhen"
    />
  </Template>
);

export default withIntl(Protection);
