import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage, FormattedMessage, injectIntl } from 'react-intl';
import { Motion, spring } from 'react-motion';
import { Item, Number, Label, Unit } from './styled';

function CountTo({ number, label, ready, units }) {
  const count = ready ? number : 0;

  const getUnits = () => {
    if (number % 10 === 1) {
      return units.one;
    }
    return units.other;
  };

  return (
    <Item>
      <Label>{label ? <FormattedHTMLMessage id={label} /> : null}</Label>
      <Motion
        defaultStyle={{ x: 0 }}
        style={{
          x: spring(count, { precision: 1, stiffness: 80, damping: 30 })
        }}
      >
        {value => <Number>{Math.floor(value.x)}</Number>}
      </Motion>
      <Unit>
        <FormattedMessage id={getUnits()} />
      </Unit>
    </Item>
  );
}

CountTo.propTypes = {
  number: PropTypes.number,
  label: PropTypes.string,
  units: PropTypes.shape({}),
  ready: PropTypes.bool
};

CountTo.defaultProps = {
  number: 34,
  label: '',
  units: {
    one: '',
    other: ''
  },
  ready: false
};

export default injectIntl(CountTo);
