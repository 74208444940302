import styled from 'styled-components';
import Image from 'components/Image';

export const Wrapper = styled.section`
  display: inline-block;
  height: auto;
  margin: 5% 0 25% 0;
  position: relative;
  width: 100%;
`;

export const StyledImage = styled(Image)`
  height: auto;
  width: 100%;
`;

export default Wrapper;
