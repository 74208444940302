import React from 'react';
import Parallax from 'containers/Parallax';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Inner, Outer } from 'containers/Wrapper';
import Figure from 'components/Figure';
import { Wrapper } from './styled';

const Block = () => (
  <Outer>
    <Inner>
      <Wrapper>
        <Row center="xs">
          <Col xs={12} sm={5}>
            <Parallax offsetYMax="300px" offsetYMin="0px" slowerScrollRate>
              <Figure
                image="protection/jordan-medical-centre"
                caption="protection_imageGarmentmedCaption"
              />
            </Parallax>
          </Col>
          <Col xs={12} sm={5} smOffset={1}>
            <Parallax offsetYMax="300px" offsetYMin="-50px">
              <Figure
                image="protection/china-pregnancy"
                caption="protection_imagePregnantCaption"
              />
            </Parallax>
          </Col>
        </Row>
      </Wrapper>
    </Inner>
  </Outer>
);

export default Block;
