import styled from 'styled-components';
import { Outer, Inner } from 'containers/Wrapper';
import media from 'styles/media';
import { padding } from 'styles/variables';

export const OuterWrapper = styled(Outer)`
  position: relative;
  display: inline-block;
  margin: 5rem 0;
  height: auto;

  ${media.md`
    margin: 2rem 0 3rem;
  `};

  ${media.xs`
    margin: 2rem 0;
  `}
`;

export const InnerWrapper = styled(Inner)`
  position: relative;
`;

export const BottomLayer = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;

  ${props => media.queries('padding', props.voffset)};

  figure {
    margin: 0;
  }
`;

export const TopLayer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;

  ${props => media.queries('justify-content', props.justify)};

  ${media.xs`
    position: relative;
    margin: 0 0 5rem;
  `};
`;

TopLayer.defaultProps = {
  justify: {
    lg: 'center',
    md: 'center',
    sm: 'center',
    xs: 'center'
  }
};

export const InfoBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
`;

export const FigureWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;

  ${media.xs`
    margin: 0 0 0 -${padding.left.xs}rem;
  `};
`;
