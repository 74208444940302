import styled, { createGlobalStyle } from 'styled-components';
import { nightblue, magenta, white } from 'styles/variables';
import { h2Mixin, paragraphMixin } from 'styles/typography';

export const GlobalTippyStyles = createGlobalStyle`
  .tippy-tooltip {
    background-color: ${magenta};
    padding: 1rem 2rem;
  }

  .tippy-popper[x-placement^='bottom'] .tippy-tooltip .tippy-arrow {
    border-bottom-color: ${magenta};
  }

  .tippy-popper[x-placement^='top'] .tippy-tooltip .tippy-arrow {
    border-top-color: ${magenta};
  }

  .tippy-tooltip .tippy-roundarrow {
    fill: ${magenta};
  }
`;

export const Wrapper = styled.figure`
  width: 100%;
  height: auto;
  background-color: ${nightblue};
  margin: 2rem 0 5rem;
  padding: 0;
`

export const Image = styled.img`
  width: 100%;
  height: auto;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`

export const SVG = styled.svg`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;

  circle {
    cursor: pointer;
  }
`

export const Tooltip = styled.div`
  width: 100%;
  height: 100%;
  min-width: 250px;
  color: ${white};
  overflow: hidden;
  text-align: initial;

  h4 {
    ${h2Mixin};
    font-weight: 500;
    margin: 0;
    line-height: 2.8rem;
    margin: 0 0 1rem;
    letter-spacing: -1px;
  }

  p {
    ${paragraphMixin};
    font-size: 1rem;
  }
`