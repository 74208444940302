import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import Soundbyte from 'components/Sound/Soundbyte';

export default class index extends Component {
  static propTypes = {
    children: PropTypes.node,
    id: PropTypes.string,
    volume: PropTypes.number
  };

  static defaultProps = {
    children: null,
    id: '',
    volume: null
  };

  state = {
    isPlaying: false
  };

  handleVisibilityChange = isVisible => {
    this.setState({
      isPlaying: isVisible
    });
  };

  render() {
    const { id, children, volume, visibilitySensorProps } = this.props;
    const { isPlaying } = this.state;

    return (
      <Fragment>
        <Soundbyte loop volume={volume} id={id} playing={isPlaying} />
        <VisibilitySensor
          {...visibilitySensorProps}
          partialVisibility
          onChange={this.handleVisibilityChange}
        >
          {children}
        </VisibilitySensor>
      </Fragment>
    );
  }
}
