import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';
import Parallax from 'containers/Parallax';
import Figure from 'components/Figure';
import { Row, Col } from 'react-styled-flexboxgrid';
import InfoBox from 'components/InfoBox';
import {
  OuterWrapper,
  InnerWrapper,
  TopLayer,
  BottomLayer,
  FigureWrapper
} from './styled';

const InfoParallax = ({ parallax, figure, infoBox, voffset, className }) => {
  const infoBoxArr = Array.isArray(infoBox) ? infoBox : [infoBox];
  return (
    <OuterWrapper className={className}>
      <InnerWrapper>
        {infoBoxArr.map(info => (
          <TopLayer justify={info.justify} key={info.message}>
            <Row>
              <Col {...info.colProps}>
                <InfoBox {...info.infoBox}>
                  <FormattedHTMLMessage id={info.message} />
                </InfoBox>
              </Col>
            </Row>
          </TopLayer>
        ))}
        <BottomLayer voffset={voffset}>
          <Parallax {...parallax}>
            <Row>
              <Col {...figure.colProps}>
                <FigureWrapper>
                  <Figure {...figure} />
                </FigureWrapper>
              </Col>
            </Row>
          </Parallax>
        </BottomLayer>
      </InnerWrapper>
    </OuterWrapper>
  );
};

InfoParallax.propTypes = {
  parallax: PropTypes.shape({}),
  figure: PropTypes.shape({}),
  infoBox: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ]),
  voffset: PropTypes.shape({})
};

InfoParallax.defaultProps = {
  parallax: {
    offsetYMax: 20,
    offsetYMin: -20
  },
  figure: {
    width: '100%',
    image: '',
    caption: '',
    colProps: {}
  },
  infoBox: {
    justify: {},
    infoBox: {
      scroll: false,
      size: {}
    },
    message: '',
    colProps: {}
  },
  voffset: {
    lg: 0,
    md: 0,
    sm: 0,
    xs: 0
  }
};

export default InfoParallax;
