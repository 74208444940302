/* eslint-disable import/prefer-default-export */

import styled from 'styled-components';
import { Outer, Inner } from 'containers/Wrapper';
import media from 'styles/media';

export const Wrapper = styled(Outer)`
  width: 100%;
  height: auto;
  display: inline-block;
`;

export const InnerWrapper = styled(Inner)`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Counters = styled.div`
  flex: 0 0 80%;
  display: flex;
  flex-flow: wrap-reverse;
  justify-content: center;
  align-items: flex-start;

  ${media.xs`
    flex-flow: row wrap;
  `};
`;

export const CountWrapper = styled.div`
  flex: 1 1 25%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;

  ${media.xs`
    flex: 0 0 100%;
  `};
`;
