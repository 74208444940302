import styled from 'styled-components';
import { h1Mixin, paragraphMixin } from 'styles/typography'
import { yellow } from 'styles/variables';
import media from 'styles/media';

export const Item = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 15rem;
  margin: 2rem 0;

  ${media.xs`
    width: 100%;
  `};
`;

export const Number = styled.div`
  ${h1Mixin};
  color: ${yellow};
  margin: 0;
  padding: 0;
  letter-spacing: -5px;

  ${media.xs`
    font-size: 8rem;
    line-height: 8rem;
  `};
`;

export const Label = styled.div`
  ${paragraphMixin};
  font-weight: 500;
  margin: 0;
  text-align: center;
  line-height: 1.4rem;
`

export const Unit = styled.div`
  ${paragraphMixin};
  text-align: center;
  margin: 0;
`;
